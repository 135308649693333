import request from "api/request";
import crewordRequest from "./crewordRequest";
//获取授权作品列表
export function getList(data) {
  return request({
    url: "/portfolio/listPage",
    method: "post",
    data,
  });
}

//获取收支记录
export function findRevenueExpendInfo(data) {
  return request({
    url: "/income/findRevenueExpendInfo",
    method: "post",
    data,
  });
}
export function authorizationList(data) {
  return request({
    url: "/portfolio/authorizationList",
    method: "post",
    data,
  });
}
//获取排名
export function findCopyrightIntegralOrder(data) {
  return request({
    url: "/income/findCopyrightIntegralOrder",
    method: "get",
    data,
  });
}

//获取现金、字符数量
export function findRevenue(data) {
  return request({
    url: "/income/findUserRevenue",
    method: "get",
    data,
  });
}

// 获取积分
export function findCopyrightIntegral(data) {
  return request({
    url: "/income/findCopyrightIntegral",
    method: "get",
    data,
  });
}

// 修改创作时间
export function publishTime(data) {
  return request({
    url: "/portfolio/update/publishTime",
    method: "post",
    data,
  });
}

// 余额记录
export function findBalanceInfo(data) {
  return request({
    url: "/card/salesrecord/findBalanceInfo",
    method: "post",
    data,
  });
}

// 添加授权承诺
export function addCommitment(data) {
  return request({
    url: "/copyright/add/commitment",
    method: "post",
    data,
  });
}

// 校验授权承诺
export function commitment() {
  return request({
    url: "/copyright/check/commitment",
    method: "post",
  });
}

// 校验用户是否实名
export function checkUserRealname() {
  return request({
    url: "/realname/checkUserRealname",
    method: "get",
  });
}

// 兑换现金
export function exchangeMoney(data) {
  return request({
    url: "/income/exchangeMoney",
    method: "POST",
    data,
  });
}

// 提现
export function addCashMoney(data) {
  return request({
    url: "/income/addCashMoney",
    method: "POST",
    data,
  });
}

// 修改作品价格
export function updatePrice(data) {
  return request({
    url: "/portfolio/update/price",
    method: "POST",
    data,
  });
}

// 作品上下架
export function updateShelves(data) {
  return request({
    url: "/portfolio/update/shelves",
    method: "POST",
    data,
  });
}

// 获取语境库
export function thirdpart() {
  return request({
    url: "/contextual/thirdpart",
    method: "POST",
  });
}

//删除作品
export function delWorks(data) {
  return request({
    url: "/portfolio/del",
    method: "post",
    data,
  });
}

//作品分类类型列表
export function worksTypeList(data) {
  return crewordRequest({
    url: "/portfolio/portfolio-type/menuSearch",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//作品分类类型列表
export function menuSearchByType(data) {
  return crewordRequest({
    url: "/portfolio/portfolio-type/menuSearchByType",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// 获取作者信息
export function workerInfo() {
  return request({
    url: "/portfolio/userPortfolioInfo",
    method: "post",
  });
}

//添加作品
export function addNewFile(data) {
  return request({
    url: "/portfolio/upload",
    method: "post",
    data,
  });
}
export function updateFileInfo(data) {
  return request({
    url: "/portfolio/update/info",
    method: "post",
    data,
  });
}

// 查询授权签名
export function tempSignatureCheck(data) {
  return request({
    url: "/copyright/check/tempSignature",
    method: "post",
    data,
  });
}

// aigc稿生成历史
export function logUserDisplay(data) {
  return crewordRequest({
    url: "/corpuslog/logUserDisplay",
    method: "post",
    data,
  });
}
